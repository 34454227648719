import Main from '@/layout/main.vue'
const routes = [
    {
        path: '/instructions',
        name: 'instructions',
        meta: { title: '操作说明' },
        redirect: '/instructions/menus',
        component: Main,
        children: [{
            path: '/instructions/menus',
            name: 'instructions_menus',
            meta: {
                title: '菜单',
                icon: "icon_qudao_weixin",
                parentPath: '/instructions',
                permission: ['view']
            },
            component: () => import('@/views/instructions/menus.vue')
        },{
            path: '/instructions/menus_edit',
            name: 'instructions_menus_edit',
            meta: {
                title: '编辑菜单',
                icon: "icon_qudao_weixin",
                parentPath: '/instructions',
                permission: ['view'],
                hidden: true
            },
            component: () => import('@/views/instructions/menus_edit.vue')
        },{
            path: '/instructions/article',
            name: 'instructions_article',
            meta: {
                title: '操作文档',
                icon: "icon_qudao_weixin",
                parentPath: '/instructions',
                permission: ['view']
            },
            component: () => import('@/views/instructions/article.vue')
        },{
            path: '/instructions/article_edit',
            name: 'instructions_article_edit',
            meta: {
                title: '编辑文档',
                icon: "icon_qudao_weixin",
                parentPath: '/instructions',
                permission: ['view'],
                hidden: true
            },
            component: () => import('@/views/instructions/article_edit.vue')
        },{
            path: '/instructions/notice',
            name: 'instructions_notice',
            meta: {
                title: '系统公告',
                icon: "icon_qudao_weixin",
                parentPath: '/instructions',
                permission: ['view']
            },
            component: () => import('@/views/instructions/notice.vue')
        },{
            path: '/instructions/notice_edit',
            name: 'instructions_notice_edit',
            meta: {
                title: '系统公告',
                icon: "icon_qudao_weixin",
                parentPath: '/instructions',
                permission: ['view'],
                hidden: true
            },
            component: () => import('@/views/instructions/notice_edit.vue')
        }]
    }
]

export default routes